.nav-link{
    color:black;
}
.nav-link:active{
    color:blue;
}
.nav-link:focus{
    color:blue;
}
.borderStyle{
    border-style: inset;
}
.card-detail-max-width{
    max-width: 692px;
}
.fixed{
    position: fixed;
    background-color: rgb(0, 0, 0,0.4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: auto;
}
.bg-fixed{
    /* background-color: rgb(0, 0, 0,0.4); */
    position: fixed;
    z-index: 1001;
    left: 25%;
    top: 0;
    width: 692px;
    height: 100%;
    overflow: auto;
}

