

.CompanyName {
    text-align: center;
    padding: 10px;
    color: #00072B;
    margin-top: 0px;
    font-weight: bold;
    font-size: 20px;
}

#sidenav-block {
    position: fixed;
    display: block;
    margin-left: 200px;
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12); */
    background-color: #D1D5DB;
    /* border-radius: 50px 0px 0px 50px !important; */
}


.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T {
    cursor: default;
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12); */
    background-color: #DDA81C;
    color:#212529;

    /* border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important; */
}

.sc-ifAKCX .iAgGBH .sidenav-navitem---3r9ER .selected---91VwC .highlighted---1vG8Y {
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);

    border-radius: 50px 0px 0px 50px !important; */
    background-color: #00A991;

}
.sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 > *{
    display: flex;
    align-items: center;
}
.sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 > * > .sidenav---navitem---9uL5T{
    align-items: center;
    display: flex;
    gap:10px;
    width:100%;
}
.sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 > * > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    display: flex;
    align-items: center;
}
.sidenav---sidenav-subnavitem---1cD47[role="heading"] {
    display: none;
}
.sidenav---sidenav-subnavitem---1cD47 .sidenav---navtext---1AE_f{
    padding-top: 10px;
    padding-bottom  : 10px;
    color:#D1D5DB
}

.sidenav---sidenav-subnavitem---1cD47 .sidenav---navtext---1AE_f::after{
    font-family: 'Font Awesome 5 Free' !important;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
    content: " ";
    width: 100%;
    height: 48px;
    position: absolute;
    top: 0;
    z-index: -1;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:hover{
    background-color: rgba(255,255,255,0.2);
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T:hover {
    background-color: #DDA81C;
    color:#212529;
}
.sidenav---navitem---9uL5T:hover .sidenav---sidenav-subnavitem---1cD47 .sidenav---navtext---1AE_f{
     color:#212529;
     position:relative
}

.sidenav---sidenav-subnavitem---1cD47{
    position:relative;
}
.sidenav---sidenav-subnav---1EN61 {
    background: linear-gradient(90deg, rgb(167, 55, 55) 0%, rgb(122, 40, 40) 100%);
    border:0;
    padding:0;

}
.sidenav---selected---1EK3y {
    background-color: #DDA81C;
    color:#212529;
}
.sidenav---sidenav-subnav---1EN61 .sidenav---selected---1EK3y .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f{
     color:#212529;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:nth-child(2){
    margin: 0;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:last-child{
    margin:0
}

.sidenav---expanded .sidenav---sidenav-navitem-- :hover {
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);

    border-radius: 50px 0px 0px 50px !important; */
    background-color: #00A991;

}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61{
    left: 100%;
    position: absolute;
    background: linear-gradient(90deg, rgb(167, 55, 55) 0%, rgb(122, 40, 40) 100%);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T::before{
    display: none;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
    display:"flex" !important
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo[role='menuitem']{
    background-color: #DDA81C;
    color:#212529;
}
/* box search */
.input-icons {
    margin-left: 5%;
}

.input-icons i {
    position: absolute;
    color: lightgray;
}

/* .input-icons {
    width: 2%;
    margin-bottom: 10px;
  } */

.icon {
    padding: 10px;
    /* min-width: 40px; */
}
.navbar-expand .navbar-nav .dropdown-menu{
    position: absolute;
    right:10px;
    top:40px;
}
nav.navbar.navbar-expand.navbar-light.bg-white.fixed-top > div > div{
    position: relative;
}
.dropdown-menu[data-bs-popper]{
    right:10px;
}


.input-field {
    width: 300px;
    padding: 5px;
    padding-left: 30px;
    background-color: #00A991;
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);
}

/* edn box search */

.iconsforsidenav::after{
       content: "\f095";
    font-family: 'Font Awesome 6 Free' !important;
    position:absolute;
    z-index: 100;
    right:20px;
}
