* {
  border-color: #D1D5DB;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-tabs .nav-link {
  background-color: #F9F9F9;
  border: 1px solid #dddddd;
  border-bottom: 0;
  color: #252A31;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #2E72D2;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0;
}

.page-item .page-link {
  color: #00000080;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-item.active .page-link {
  z-index: 3;
  color: #3E66FB;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3E66FB;
  border: 1px solid rgb(236, 238, 255);
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: 6px;
}

tr {
  line-height: 25px;
  min-height: 25px;
  height: 55px;
}

th:first-child {
  width: 5%;
}

.custom_nav_link {
  align-self: flex-end !important;
}

.card {
  border: none;
}

.sidenav---sidenav-nav---3tvij {
  color: #D1D5DB !important;
}

.card-detail {
  margin-bottom: 100px;
}

.buttonRedColor {
  background-color: #960000;
  color: #fff;
  border: none
}

.buttonRedColor-export {
  background-color: #960000;
  color: #fff;
  width: 105px;
  height: 35px;
  border-radius: 6px;
  border: none;
}

.text-danger {
  color: red;
}


element.style {}

.dropdown-menu[data-bs-popper] {
  right: 10px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 9rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0.25rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  overflow-wrap: break-word;
  overflow: hidden;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-cover {
  width: 170px;
  height: 170px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-add-staff {
  padding: 3px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border: 1px solid #666;
  height: 170px;
  width: 170px;
  cursor: pointer;
}

.image-show-staff {
  padding: 3px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border: 1px solid #666;
  height: auto;
  width: auto;
}

.center-point {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F7;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-Radius: 5px;
  cursor: pointer;
}

.btn-delete-cabinet {
  padding: 10px 20px;
  font-weight: 700;
  display: flex;
  gap: 10;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  z-index: 100;
}

.btn-add-stock-to-cabined{
  background-color: #E5E5E7;
  padding: 10px;
  padding-left: 54px;
  padding-right: 54px;
  border: none;
  color: #1059BF;
  margin-bottom: 20px
}


.card-add-stock-to-manager {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DFE2E9;
  height: 100%;
  padding-right: 10px;
}

.shw-number-stock {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 700;
  font-size: 22px;
  background-color: #960000;
  width: 40px;
  height: 40px;
}

.mg-x-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.number-owner {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.delete-owner {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #D21C1C;
}

.btn-add-owner {
  color: #1059BF;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, ">");
}

a {
  color: #0d6efd;
  text-decoration: none;
}

.title-detail {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: bold
}

.btn-print-report {
  width: 260px;
  background-color: #960000;
  border: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.btn-print-report:hover {
  width: 260px;
  background-color: #960000;
  border: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.btn-red-outline {
  border: 2px solid #960000;
  background-color: transparent;
  color: #960000;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.btn-red-outline:hover {
  border: 2px solid #960000;
  background-color: #960000;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.loading-cus {
  margin-left: 11px;
}

.loading-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 50vh;
}

.card-sticky {

  position: -webkit-sticky;
  position: sticky !important;
  top: 0px;
  background-color: yellow;
  padding: 50px;
  font-size: 20px;

}

.tb-fix {
  position: relative;
}

.stickyHead {
  position: sticky;
  background-color: #282c34;
}

.tableFixHead {
  overflow-y: auto;
  /* make the table scrollable if height is more than 200 px  */
  height: 600px;
  /* gives an initial height of 200px to the table */
}

.tableFixHead thead th {
  position: sticky;
  /* make the table heads sticky */
  background-color: #FFFF;
  top: 0px;
  /* table head will be placed from the top of the table and sticks to it */
}

.table-fix {
  border-collapse: collapse;
  /* make the table borders collapse to each other */
  width: 100%;
}


/* report all */
.report-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.report-main .report-card {
  border-radius: 6px;
  width: 16%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 10px; */
  line-height: 10px;
}

.report-main .report-total-rece {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.report-main .report-card .report-summery {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(3, 64, 96, 0.03);
  padding: 5px;
  color: #034060;
  font-size: 14px;
  border-radius: 6px 6px 0px 0px;
}
.report-main .report-card .report-summery1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(3, 64, 96, 0.03);
  color: #034060;
  padding: 5px;
  font-size: 14px;
  border-radius: 0px 0px 6px 6px;
}

.report-main .report-text-total{
  font-weight: 700;
font-size: 18px;
/* line-height: 29px; */
color: #008060;
}

.report-main .report-text-total-orange{
  font-weight: 700;
  font-size: 18px;
  /* line-height: 29px; */
  color: #FF8F0C;
}

.report-main .report-text-title{
  font-weight: 400;
  font-size: 14px;
  /* line-height: 23px; */
  color: #034060;
}

.report-main .report-card .report-summery p{
 padding-top: 5px;
 line-height: 3px;
}
.report-main .report-card .report-summery1 p{
  padding-top: 5px;
  line-height: 3px;
 }

.report-main .report-total-gold {
  display: flex;
  flex-direction: column;
}

.report-main .report-total-gold .report-sub-total-gold {
  height: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
}


.report-main .report-bg-green {
  background: #DDEAE7;
}

.report-main .report-bg-lightblue {
  background: #EAF1FB;
}

.report-main .report-card .report-bg-orange {
  background: #FCF6E8;
}

/* report all */

/* --------- dashboard --------------*/
.dashboard-grid {
  /* padding: 2.5px; */
  padding: 4px;
}
.dashboard-menu-col {
  width: 12%;
  text-align: center;
}
.dashboard-menu-text {
  padding: 5px;
  font-size: 10pt;
}
.dashboard-container {
  height: 100%;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.d-flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.d-flex-center-space-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.d-flex-center-space-around-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.d-flex-center-space-evenly-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.dashboard-text-all-total {
  font-weight: bold;
  font-size: 18px;
}

.dashboard-title{
  font-weight: 400;
  font-size: 14px;
  color: #034060;
}
.dashboard-menu-type {
  width: 100%;
  border-radius: 6px 6px 0 0;
  padding: 15px;
  background: #dfeaf9;
  display: flex;
  justify-content: center;
}

.dashboard-summery-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(3, 64, 96, 0.1);
  padding: 10px 5px;
  color: #034060;
  font-size: 14px;
  border-radius: 6px 6px 0px 0px;
}
.dashboard-summery-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(3, 64, 96, 0.1);
  padding: 10px 5px;
  color: #034060;
  font-size: 14px;
  border-radius: 0px 0px 6px 6px;
}

.dashboard-text-darkblue {
  color: #034060;
}
.dashboard-text-green {
  color: #008060;
}
.dashboard-text-orange {
  color: #FF8F0C;
}
.dashboard-text-red {
  color: #AF4747;
}

.dashboard-bg-green {
  background: #DDEAE7;
}

.dashboard-bg-lightblue {
  background: #EAF1FB;
}

.dashboard-bg-orange {
  background: #FCF6E8;
}
.dashboard-bg-red {
  background: #F9ECEC;
}
/* dashboard */


/*---------- document -----------------*/
.document-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.document-head .doc-head {
  font-weight: 400;
  font-size: 22px;
  color: #034060;
}

.document-head .btn-head {
  background: #960000;
  border-radius: 3px;
  width: 163px;
  height: 44px;
  padding: 10px 16px 10px 12px;
  border: 0;
  color: #fff;
  outline: none;
}

.document-head .btn-head:focus {
  outline: none;
}

.group-icon-with-input {
  display: flex;
  align-items: center;
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 2px;
}

.search-icon {
  margin-right: 10px;
  margin-left: 10px;
  color: #aaa;
}

.search-input {
  border: none;
  font-size: 16px;
  padding: 7px;
  width: 100%;
}

.search-input:focus {
  outline: none;
}

.search-input::placeholder {
  color: #aaa;
}


.create-doc .create-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-doc .create-head .title {
  font-weight: 700;
  font-size: 22px;
  color: #034060;
}

.create-doc .create-role {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  width: 100%;
}

.create-doc .create-role .create-role-box {
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 25px;
  background: #F6F6F7;
  border-radius: 8px;
  height: 38px;
  padding: 8px 16px;
  gap: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #262626;
  margin: 7px;
  cursor: pointer;
}

.create-doc .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.create-doc .create-btn {
  width: 240px;
  height: 49px;

}

.create-doc .footer .btn-cancel {
  background: #F6F6F7;
  border-radius: 3px;
  border: 0;
  color: #034060;
  font-weight: 700;
  font-size: 14px;
}

.create-doc .footer .btn-submit {
  background: #960000;
  border-radius: 3px;
  border: 0;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
}




.detail-doc .detail-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-doc .detail-head .title {
  font-weight: 700;
  font-size: 22px;
  color: #034060;
}

.detail-doc .detail-text {
  background: #F6F6F7;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  color: #034060;
  word-wrap:break-word;
  display:inline-block;
  width: 100%;
}

.detail-doc .detail-file-doc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #F6F6F7;
  border-radius: 6px;
  height: 40px;
  padding: 0px 10px 0px 10px;
  margin-bottom: 7px;
}

.detail-doc .detail-file-doc .detail-file-title {
  font-weight: 700;
  font-size: 14px;
  color: #2E72D2;
}

.detail-doc .detail-file-doc .detail-file-download {
  font-weight: 400;
  font-size: 14px;
  color: #2E72D2;
  text-decoration: none;
}

.detail-doc .detail-file-doc .detail-file-download:hover {
  width: 121px;
  height: 32px;
  background: #2E72D2;
  border-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .detail-doc .img-box{
  margin: 10px;
} */

.detail-doc .detail-img-box {
  background: #FFFFFF;
  border: 1.5px solid #BDBDBD;
  border-radius: 2px;
  align-self: stretch;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.detail-image {
  width: 100%;
  height: 200px;
  background: #FFFFFF;
  border: 1.5px solid #BDBDBD;
  padding: 5px;
}

.modal-image {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border: 1.5px solid #BDBDBD;
  padding: 5px;
  object-fit: cover;
}

.detail-doc .detail-manage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.detail-doc .detail-manage .detail-btn {
  width: 44px;
  height: 44px;

}

.detail-doc .detail-manage .btn-edit {
  background: #F6F6F7;
  border-radius: 3px;
  border: 0;
  color: #2E72D2;
  font-weight: 700;
  font-size: 14px;
}

.detail-doc .detail-manage .btn-delete {
  background: #F6F6F7;
  border-radius: 3px;
  border: 0;
  color: #D82C0D;
  font-weight: 700;
  font-size: 14px;
}


.document-file-upload {
  display: flex;
  flex-direction: row;
  /* overflow-y: scroll; */
  flex-wrap: wrap;
  gap: 5px;
}

.document-file-upload .box-upload-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  line-height: 10px;
  padding: 10px;
  width: 96%;
  min-height: 22vh;
  border-style: dashed;
  cursor: pointer;
}

.document-file-upload .box-upload-file .title-upload-head {
  color: #2E72D2;
  font-weight: 400;
  font-size: 16px;
}

.document-file-upload .box-upload-file .title-upload-text {
  color: #B0B0B0;
  /* font-weight: 400; */
  font-size: 16px;
}

.document-file-upload .box-upload-file-done {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 8vh;
  width: 24%;
  border-bottom: 5px solid green;
}

.document-file-upload .box-upload-file-done .upload-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  line-height: 15px;
}

.detail-doc .create-role {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  width: 100%;
}


.detail-doc .create-role .create-role-box {
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 25px;
  background: #F6F6F7;
  border-radius: 8px;
  height: 38px;
  padding: 8px 16px;
  gap: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #262626;
  margin: 7px;
  cursor: pointer;
}

/*------------ reader---------- */
.reader-box{
  height: 600px;
  overflow-y: scroll;
}

.reader-main {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-self: center;
  margin-bottom: 10px;
  /* height: 400px; */
}

.reader-main img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.reader-main .reader-info {
  display: flex;
  flex-direction: column;
}

.reader-main .reader-info p {
  font-weight: 400;
  font-size: 14px;
  color: #555246;
}

.reader-main .reader-info span {
  font-weight: 400;
  font-size: 10px;
  color: #555246;
}


/* popup document */
.bell-icon {
  position: relative;
}

.popup-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  width: 540px;
  height: 55vh;
  border-radius: 10px;
  position: absolute;
  right: 0;
  padding: 0;
  list-style: none;
  top: 25px;
  display: none;
  /* overflowY: "scroll", */
  /* overflow-y: "scroll"; */
}



.popup-menu li {
  padding: 10px;
  background: rgba(167, 55, 55, 0.1);

}

.popup-menu li a {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: none;
  gap: 10px;
  align-items: center;
}

.popup-menu li a .popup-menu-detail {
  display: flex;
  flex-direction: column;
}

.popup-menu li a .popup-menu-icon {
  background: #D1D5DB;
  color: #AF4747;
  font-weight: 900;
  font-size: 18px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-menu li:hover {
  background: rgba(167, 55, 55, 0.3);
}



.popup-menu .popup-read{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #D1D5DB;
}

.popup-menu .unRead, .dongRead{
  padding-bottom: 10px;
  width: 50%;
  text-align: center;
}


.noti-doc{
  color: #333;
  display: flex;
  flex-direction: none;
  gap: 10px;
  align-items: center;
  padding: 10px;
  background: rgba(167, 55, 55, 0.1);
  cursor: pointer;
}


.noti-doc .popup-menu-icon {
  background: #D1D5DB;
  color: #AF4747;
  font-weight: 900;
  font-size: 18px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noti-doc .popup-menu-detail {
  display: flex;
  flex-direction: column;
}


.noti-footer .readAfter, .readDone{
  height: 47px;
  border-radius: 3px;
  border:none;
}
.noti-footer .readDone{
  width: 120px;
  background: #2E72D2;
  color: #ffffff ;
  font-weight: 700;
font-size: 14px;
}
.noti-footer .readAfter{
  width: 93px;
  background: #E8EDF1;
  color: #555246;
  font-weight: 700;
font-size: 14px;
}





.ck-editor .ck-editor__main .ck-content {
  min-height: 300px;
}

.notic-text {
  position: absolute;
  margin-left: 24px;
  margin-top: -18px;
  border-radius: 20px;
}

.limit-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}
/*---------- document -----------------*/
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -40px;
  margin-top: 0.125rem;
}